<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <div class="row">
          <div v-for="(data, index) in datacard" :key="index" :class="'col-xs-2 col-sm-2 col-md-2 col-lg-2'">
            <div class="card mb-3" :style="'border-color:' + getDynamicColor(index)">
              <div class="card-body" :style="data.kecamatan === 'Kedungkandang' ? 'padding: 1.33rem 1rem;' : 'padding: 1.33rem 1rem;'">
                <h5 class="card-title" v-if="data.kecamatan !== 'Kedungkandang'">{{ data.kecamatan }}</h5>
                <h6 v-else class="card-title">Kedungkandang</h6>
                <p class="card-text float-right" :style="'color:' + getDynamicColor(index)">
                  <label :class="index === 3 ? 'text-primary float-right' : 'float-right'" style="margin: 0 !important">NPL {{ data.npl }}%</label>
                  <br>
                  <label :class="index === 3 ? 'text-primary float-right' : 'float-right'" style="margin: 0 !important">{{ data.jumlahnasabah }} Nasabah</label>
                  <br>
                  <label :class="index === 3 ? 'text-primary float-right' : 'float-right'" style="margin: 0 !important; text-overflow: ellipsis">Rp. {{ formatCurrency(data.totalkredit) }}</label>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-3">
        <div class="row">
          <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
            <a-card class="border-primary" :headStyle="{borderColor: '#4C7BF3'}"> <!-- :headStyle="{borderColor: '#4C7BF3'}" style="border-color: #4C7BF3" -->
              <template slot="title">
                <strong>Kualitas 5 - Macet</strong>
              </template>
              <bar-chart
                id="barDatabarchart"
                style="margin-top: 10px;"
                :key="chartkey"
                :chartdata="datacollection.kolek5"
                :options="options"
                :height="200"
              />
            </a-card>
          </div>
          <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
            <a-card class="border-primary" :headStyle="{borderColor: '#4C7BF3'}"> <!-- :headStyle="{borderColor: '#FFDA0A'}" style="border-color: #FFDA0A" -->
              <template slot="title">
                <strong>Kualitas 4 - Diragukan</strong>
              </template>
              <bar-chart
                id="barDatabarchart"
                style="margin-top: 10px;"
                :key="chartkey"
                :chartdata="datacollection.kolek4"
                :options="options"
                :height="200"
              />
            </a-card>
          </div>
          <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
            <a-card class="border-primary" :headStyle="{borderColor: '#4C7BF3'}"> <!-- :headStyle="{borderColor:'#FFA36C'}" style="border-color: #FFA36C" -->
              <template slot="title">
                <strong>Kualitas 3 - Kurang Lancar</strong>
              </template>
              <bar-chart
                id="barDatabarchart"
                style="margin-top: 10px;"
                :key="chartkey"
                :chartdata="datacollection.kolek3"
                :options="options"
                :height="200"
              />
            </a-card>
          </div>
          <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-4">
            <a-card>
              <template slot="title">
                <strong>Last Customer Visit</strong>
              </template>
              <vue-custom-scrollbar style="height: 618px; padding: 0 1.4rem;">
                <a-list
                  item-layout="vertical"
                  size="large"
                  :data-source="dataall"
                >
                  <div slot="footer"><b>Ini </b> footer</div>
                  <a-list-item
                    slot="renderItem"
                    key="item.title"
                    slot-scope="item"
                  >
                    <!-- <template slot="actions">
                </template> -->
                    <div class="row">
                      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <a-list-item-meta>
                          <template slot="description">
                            Kunjungan AO {{ item.nama_ao }} kepada nasabah
                            {{ item.nama_nasabah }}
                          </template>
                          <a slot="title" :href="item.href">{{ item.nama_ao }} - {{ convRelative(item.time) }}</a>
                          <a-avatar slot="avatar" :src="item.img_ao" />
                        </a-list-item-meta>
                        {{ item.keterangan }}
                        <div class="mt-3">
                          <span>
                            <a-icon type="like-o" style="margin-right: 8px;" />
                            {{ item.like }}
                          </span>
                          <a-divider class="mr-2 ml-2" type="vertical" />
                          <span>
                            <a-icon type="audit" style="margin-right: 8px;" />
                            Kunjungan ke {{ item.kunjungan_ke }}
                          </span>
                          <a-divider class="mr-2 ml-2" type="vertical" />
                          <span @click="openmodalreplies(item.comment)" style="cursor: pointer;">
                            <a-icon type="message" style="margin-right: 8px;" />
                            {{ item.comment.length }}
                          </span>
                        </div>
                        <strong class="mt-2 mb-1" @click="openmodalreplies(item.comment)" style="cursor: pointer;"
                          >Comments</strong
                        >
                      </div>
                      <!-- <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                        <img width="272" alt="logo" :src="item.img_kunjungan" @click="zoomGambar(item, 'img_kunjungan', 'nama_ao')" style="cursor: pointer;" />
                      </div> -->
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                      <div class="row">
                        <div
                          class="col-xs-12 col-sm-12 col-md-12 col-lg-12"
                          v-for="(replies, index) in item.comment"
                          :key="index"
                        >
                          <div v-if="index <= 2">
                            <strong class="mr-2">{{ replies.nama_ao || "" }}</strong>
                            <label style="margin: 0 !important">{{ replies.comment }}</label>
                          </div>
                          <div v-if="index <= 2 && item.comment.length - 3 > 0">
                            <strong class="mr-2" @click="openmodalreplies(item.comment)" style="cursor: pointer;"
                              >See
                              {{
                                item.comment.length === 0
                                  ? 0
                                  : item.comment.length - 3
                              }}
                              more comments</strong
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </a-list-item>
                </a-list>
              </vue-custom-scrollbar>
            </a-card>
          </div>
          <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-4">
            <a-card>
              <template slot="title">
                <strong>Jumlah nasabah overal</strong>
              </template>
              <PieChart
                :key="chartkey"
                id="pieDatabarchart"
                :chart-data="chartDataPie"
                :options="pieChartOptions"
                :height="255"
              />
            </a-card>
            <a-card class="mt-3">
              <template slot="title">
                <strong>Komposisi Per AO</strong>
              </template>
              <DoughnutChart
                :key="chartkey"
                ref="skills_chart"
                :chart-data="chartDataDonat"
                :height="255"
                :options="chartOptions">
              </DoughnutChart>
            </a-card>
          </div>
          <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-4">
            <!-- v-infinite-scroll="handleInfiniteOnLoad"
            :infinite-scroll-disabled="busy"
            :infinite-scroll-distance="10" -->
            <a-card>
              <template slot="title">
                <strong>Account Officer</strong>
                <a-radio-group v-model="aoviewmode" class="float-right">
                  <a-radio-button value="grid">
                    Grid
                  </a-radio-button>
                  <a-radio-button value="list">
                    List
                  </a-radio-button>
                </a-radio-group>
              </template>
              <div class="demo-infinite-container">
                <a-list size="small" :data-source="dataAC" v-if="aoviewmode === 'list'">
                  <a-list-item slot="renderItem" slot-scope="item, index">
                    <a-list-item-meta
                      :key="index"
                      description=""
                    >
                      <strong slot="title">{{ item.nama_ao }}</strong>
                      <a-avatar
                        slot="avatar"
                        :src="item.img_url"
                      />
                    </a-list-item-meta>
                  </a-list-item>
                  <!-- <div slot="header">
                    Header
                  </div>
                  <div slot="footer">
                    Footer
                  </div> -->
                </a-list>
                <a-list :grid="{ gutter: 2, xs: 1, sm: 2, md: 4, lg: 4, xl: 2, xxl: 3 }" :data-source="dataAC" v-else>
                  <a-list-item slot="renderItem" slot-scope="item, index">
                    <a-card :key="index" :bordered="false">
                      <!-- <template slot="title">
                      </template> -->
                        <center>
                          <a-avatar
                            style="
                              height: 50px;
                              width: 50px;
                            "
                            slot="avatar"
                            :src="item.img_url"
                          />
                          <br>
                          <strong class="mt-1" style="overflow-wrap: break-word;">{{ item.nama_ao }}</strong>
                        </center>
                    </a-card>
                  </a-list-item>
                </a-list>
              </div>
            </a-card>
          </div>
        </div>
      </div>
    </div>
    <a-modal v-model="modalreplies.visible" title="Comments" :dialogStyle="{ top: modalreplies.commentdata.length >= 3 ? '30px' : '' }">
      <template slot="footer">
        <a-button class="btn btn-danger" @click="modalreplies.visible = false;">
          Cancel
        </a-button>
      </template>
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
          <a-list
            :locale="{
              emptyText: 'No one has commented yet'
            }"
            class="comment-list"
            item-layout="horizontal"
            :data-source="modalreplies.commentdata"
          >
            <a-list-item slot="renderItem" slot-scope="item">
              <a-comment :author="item.nama_ao || ''" :avatar="item.img_url">
                <template slot="actions">
                  <span>
                    <a-icon type="like-o" style="margin-right: 8px;" />
                    {{ item.like || 0 }}
                  </span>
                  <span>
                    <a-icon type="like-o" style="margin-right: 8px;" />
                    {{ item.dislike || 0 }}
                  </span>
                  <span>
                    <a-icon type="message" style="margin-right: 8px;" />
                    {{ item.comment.length }}
                  </span>
                  <span>
                    {{ convRelative(item.time) }}
                  </span>
                </template>
                <p slot="content">
                  {{ item.comment }}
                </p>
                <!-- <a-tooltip slot="datetime" :title="item.datetime.format('YYYY-MM-DD HH:mm:ss')">
                          <span>{{ item.datetime.fromNow() }}</span>
                        </a-tooltip> -->
              </a-comment>
            </a-list-item>
          </a-list>
        </div>
      </div>
    </a-modal>
    <zoomgambar ref="czoomgambar" />
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
import vueCustomScrollbar from 'vue-custom-scrollbar'
import moment from 'moment'
import zoomgambar from '@/components/lou/zoomgambar'
// import { Bar } from 'vue-chartjs'
import infiniteScroll from 'vue-infinite-scroll'
import BarChart from './component/barchart'
import DoughnutChart from './component/dougnutchart'
import PieChart from './component/piechart'

export default {
  directives: { infiniteScroll },
  created () {
    this.generateData()
  },
  components: {
    zoomgambar,
    vueCustomScrollbar,
    BarChart,
    PieChart,
    DoughnutChart,
  },
  data() {
    return {
      modalreplies: {
        visible: false,
        commentdata: [],
      },
      dataall: [],
      chartkey: 0,
      aoviewmode: 'grid',
      datacard: [],
      dataAC: [],
      componentKey: 0,
      chartDataDonat: {
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            data: [],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          animateRotate: false,
        },
      },
      chartData: {
        labels: ['skill1'],
        datasets: [
          {
            backgroundColor: ['#41B883'],
            data: [1],
          },
        ],
      },
      pieChartOptions: {
        responsive: true,
        legend: {
          display: true,
          // position: 'right',
        },
        maintainAspectRatio: false,
      },
      chartDataPie: {
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            data: [],
          },
        ],
      },
      datacollection: {
        kolek3: {
          labels: [],
          datasets: [
            {
              label: 'Data ku 1',
              backgroundColor: [],
              data: [],
            },
          ],
        },
        kolek4: {
          labels: [],
          datasets: [
            {
              label: 'Data ku 1',
              backgroundColor: [],
              data: [],
            },
          ],
        },
        kolek5: {
          labels: [],
          datasets: [
            {
              label: 'Data ku 1',
              backgroundColor: [],
              data: [],
            },
          ],
        },
      },
      options: {
        legend: {
          display: false,
        },
        tooltips: {
          callbacks: {
            label: function (t, d) {
              // console.log('t', t)
              // var xLabel
              // yLabel
              // if (t.datasetIndex === 0) {
              //   xLabel = d.datasets[t.datasetIndex].label
              //   yLabel = t.yLabel
              //   return xLabel + ': ' + yLabel
              // } else {
              // xLabel =
              //   t.xLabel >= 1000
              //     ? '' +
              //       t.xLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
              //     : '' + t.xLabel
              return t.yLabel + ' Nasabah'
              // }
            },
          },
        },
        scales: {
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                callback: function (value, index, values) {
                  if (parseInt(value) >= 1000) {
                    return (
                      '' +
                      value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                    )
                  } else {
                    return '' + value
                  }
                },
              },
            },
          ],
        },
      },
    }
  },
  methods: {
    zoomGambar(record, column, caption) {
      var data = {
        imageUrl: record[column],
        caption: record[caption],
      }
      this.$refs.czoomgambar.lihatgambar(data)
    },
    openmodalreplies(comment) {
      this.modalreplies.visible = true
      this.modalreplies.commentdata = comment
    },
    async generateData() {
      var res = await lou.customUrlGet('loc/dashboard', false, true)
      if (res) {
        var blokatas = res.data.blokAtas
        var grafikkualitas = res.data.Grafik_Kualitas
        var dataao = res.data.ao
        var datakunjungan = res.data.Kunjungan
        this.generateChart(blokatas, grafikkualitas, dataao, datakunjungan)
      }
    },
    generateChart(blokatas, grafikkualitas, dataao, datakunjungan) {
      // console.log('blokatas', blokatas)
      // console.log('grafikkualitas', grafikkualitas)
      this.dataall = datakunjungan
      this.datacard = blokatas
      blokatas.forEach((element, index) => {
        if (element.kecamatan !== 'Total Kredit') {
          this.chartDataPie.labels.push(element.kecamatan)
          var bc = this.getDynamicColor(index)
          this.chartDataPie.datasets[0].backgroundColor.push(bc)
          this.chartDataPie.datasets[0].data.push(element.jumlahnasabah)
        }
      })
      dataao.forEach((element, index) => {
        this.chartDataDonat.labels.push(element.nama_ao)
        var bc = this.getDynamicColor(index)
        this.chartDataDonat.datasets[0].backgroundColor.push(bc)
        this.chartDataDonat.datasets[0].data.push(element.jumlah)
      })
      grafikkualitas.Kolek3.forEach((element, index) => {
        this.datacollection.kolek3.labels.push(element.kecamatan)
        var bc = this.getDynamicColor(index)
        this.datacollection.kolek3.datasets[0].backgroundColor.push(bc)
        this.datacollection.kolek3.datasets[0].data.push(element.jumlah)
      })
      grafikkualitas.Kolek4.forEach((element, index) => {
        this.datacollection.kolek4.labels.push(element.kecamatan)
        var bc = this.getDynamicColor(index)
        this.datacollection.kolek4.datasets[0].backgroundColor.push(bc)
        this.datacollection.kolek4.datasets[0].data.push(element.jumlah)
      })
      grafikkualitas.Kolek5.forEach((element, index) => {
        this.datacollection.kolek5.labels.push(element.kecamatan)
        var bc = this.getDynamicColor(index)
        this.datacollection.kolek5.datasets[0].backgroundColor.push(bc)
        this.datacollection.kolek5.datasets[0].data.push(element.jumlah)
      })
      this.chartkey += 1
      this.dataAC = dataao
      // console.log('this.datacollection', this.datacollection)
    },
    fillData () {
      this.datacollection = {
        labels: [this.getRandomInt(), this.getRandomInt()],
        datasets: [
          {
            label: 'Data One',
            backgroundColor: '#f87979',
            data: [this.getRandomInt(), this.getRandomInt()],
          }, {
            label: 'Data One',
            backgroundColor: '#f87979',
            data: [this.getRandomInt(), this.getRandomInt()],
          },
        ],
      }
    },
    getRandomInt () {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5
    },
    getDynamicColor(index) {
      var ret = lou.dynamicColors(index)
      return ret
    },
    convRelative(date) {
      var ndate = date.replaceAll('T', ' ')
      return moment(ndate).startOf('hour').fromNow()
    },
    formatCurrency(value) {
      return lou.curency(value)
    },
  },
}
</script>

<style>
.demo-infinite-container {
  /* border: 1px solid #e8e8e8; */
  border-radius: 4px;
  overflow: auto;
  padding: 8px 24px;
  height: 255px;
}

.ant-card-body {
  padding: 10px !important;
}

.ant-card-haed {
  padding: 0 14px !important;
}
</style>
